<template>
    <div class="indexbox">
        <div style="margin-bottom: 8px">
            <div class="indexBoxLeft">
                <div class="realtime">
                    <div class="realtimetop">
                        <p class="title">数据统计</p>
                        <p class="timeTxt">更新时间：{{ nowDate }}</p>
                    </div>
                    <div class="realtimebot">
                        <div class="listItem">
                            <div class="topItem" v-for="(item, index) in realData" :key="index">
                                <div class="today">
                                    <p style="margin-top: 10px">
                                        {{ item.name }}
                                        <el-popover placement="top-start" :title="item.name + item.title" width="200"
                                            trigger="hover" :content="item.content">
                                            <i style="font-size: 16px" class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                    </p>
                                    <p>{{ item.data }}</p>
                                    <el-button type="text" style="color: #409eff"
                                        @click="$router.push(item.path)">更多</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="charts">
            <div class="realtimetop">
                <p class="title">服务订单及金额整体数据报表</p>
            </div>
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="小区名称">
                    <el-select v-model="form.community_id" size="small" clearable>
                        <el-option label="全部" value=" "></el-option>
                        <el-option :label="item.community_name" :value="item.id" v-for="item in communityList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据选择">
                    <el-select v-model="form.amount_type">
                        <el-option :label="item.name" :value="item.val" v-for="item in amount_type"
                            :key="item.val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期级别">
                    <el-select v-model="form.date_type" placeholder="请选择身份">
                        <el-option label="月" value="month"></el-option>
                        <el-option label="年" value="year"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getDateData()">搜索</el-button>
                    <el-button type="text" @click="cancelSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <div id="eBox" ref="eBox"></div>
        </div>
    </div>
</template>
  
<script>
import { getDateformat } from '@/util/getDate';
import * as echarts from 'echarts';
import _ from 'lodash';
export default {
    data () {
        return {
            indexData: {},
            nowDate: null,
            option: {},
            myChart: null,
            realData: [],
            communityList: [],
            form: {
                amount_type: 'total_price',
                date_type: 'month',
                user_type: '',
                community_id: ' ',
                search_type: 'service'
            },
            amount_type: [
                {
                    val: 'total_price',
                    name: '服务总金额',
                    color: 'orange',
                },
                {
                    val: 'deposit_price',
                    name: '服务订单总金额',
                    color: '#F86200',
                },
                {
                    val: 'final_pay_price',
                    name: '服务结算总金额',
                    color: 'orange',
                },
                {
                    val: 'fianl_reduce_price',
                    name: '服务总订单数',
                    color: 'red',
                },
            ],
        };
    },
    created () {
        this.nowDate = getDateformat(new Date().getTime() / 1000);
        this.getIndexData();
        this.getDateData();
        this.getCommunity();
    },
    methods: {
        getIndexData () {
            this.$axios.post(this.$api.index.getData).then(res => {
                if (res.code == 0) {
                    let result = res.result;
                    this.realData = [
                        {
                            name: '服务总金额',
                            data: result.service_amount,
                            path: '/finance/accounts',
                            title: '(元)',
                            content: '每笔订单的维修原价总计',
                        },
                        {
                            name: '服务订单总金额',
                            data: result.service_order_amount,
                            path: '/finance/accounts',
                            title: '(元)',
                            content: '每笔订单用户总实际支付的总计（总实际支付包含有月支付的金额）',
                        },
                        {
                            name: '物业结算总金额',
                            data: result.service_settlement.toFixed(2),
                            path: '/finance/accounts',
                            title: '(元)',
                            content: '按每笔订单的结算算法：平台给物业总结算金额',
                        },
                        {
                            name: '管理小区总数',
                            data: result.community_num,
                            path: '/finance/accounts',
                            title: '(个)',
                            content: '物业管理的全部小区数量',
                        },
                        {
                            name: '技工人数',
                            data: result.artisan,
                            path: '/finance/accounts',
                            title: '(人)',
                            content: '物业小区的全部技工人数总计',
                        },
                        {
                            name: '服务总订单数',
                            data: result.service_order_nums,
                            path: '/finance/accounts',
                            title: '(笔)',
                            content: '所有已付尾款的维修订单数量总计（包含vip不用付尾款订单）',
                        },
                    ];
                }
            });
        },
        cancelSearch () {
            this.form = {
                amount_type: 'total_price',
                user_type: '2',
                date_type: 'month',
                search_type: '',
            };
            this.getDateData();
        },
        getDateData () {
            this.$axios.post(this.$api.index.getDateData, this.form).then(res => {
                if (res.code == 0) {
                    let { result } = res;
                    let dataDate = [];
                    let dataData = [];
                    if (this.form.date_type == 'month') {
                        dataDate = result.map((item, index) => index * 1 + 1 + '日');
                        dataData = result;
                    } else {
                        dataDate = result.map(item => item.date + '月');
                        dataData = result.map(item => item.data);
                    }
                    let obj = this.amount_type.find(item => item.val == this.form.amount_type);
                    let name = obj.name;
                    let color = obj.color;

                    if (!this.myChart) {
                        let box = document.getElementById('eBox');
                        this.myChart = echarts.init(box);
                    }

                    let option = {
                        color: [color],
                        title: {},
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985',
                                },
                            },
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true,
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: dataDate,
                            },
                        ],
                        yAxis: [
                            {
                                type: 'value',
                            },
                        ],
                        series: [
                            {
                                name: name,
                                type: 'line',
                                smooth: true,
                                lineStyle: {
                                    width: 3,
                                },
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.1,
                                    color: color,
                                    origin: 'start',
                                },
                                data: dataData,
                            },
                        ],
                    };
                    this.option = option;
                    option && this.myChart.setOption(this.option);
                } else {
                    this.$message.error('处理失败');
                }
            });
        },
        getCommunity () {
            this.$axios.post(this.$api.user.communityList).then(res => {
                if (res.code == 0) {
                    this.communityList = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
    filters: {
        setTiem (index) {
            return getDateformat(index);
        },
    },
};
</script>
  
<style lang="less" scoped>
.indexbox {
    //width: 100%;
    //height: 100%;

    font-size: 14px;

    .indexBoxLeft {
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 8px;
        background-color: #fff;

        .realtime {
            .realtimebot {
                margin-top: 20px;
                padding: 0 30px 23px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .listItem {
                    display: flex;
                    flex-wrap: nowrap;

                    &+.listItem {
                        margin-top: 20px;
                    }

                    .topItem {
                        flex-shrink: 0;
                        //height: 104px;
                        border: 1px solid #f2f3f5;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 20px 0;
                        align-items: center;
                        width: 260px;

                        &+.topItem {
                            margin-left: 20px;
                        }

                        .today {
                            text-align: left;

                            p {
                                font-size: 28px;
                                font-weight: bold;
                                line-height: 30px;
                            }

                            p:first-child {
                                white-space: nowrap;
                                font-weight: 400;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .indexBoxRight {
        flex: 10;
        min-width: 478px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .righttop {
            height: 388px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 8px;
            background-color: #fff;

            &>div {
                width: 100%;
                // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
            }

            .righttopT {
                height: 388px;
                padding: 16px 24px 20px 24px;
                margin-bottom: 8px;
                display: flex;
                flex-direction: column;

                .titleBox {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #17171a;

                        &:before {
                            content: '';
                            height: 14px;
                            border-right: 3px solid #1467ff;
                            margin-right: 8px;
                        }
                    }

                    .ignoreall {
                        color: #979899;
                        cursor: pointer;
                    }
                }

                .listBox {
                    flex: 1;
                    padding-right: 10px;
                    overflow: hidden;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                        height: 5px !important;
                    }

                    .itemBox {
                        display: flex;
                        margin-bottom: 12px;
                        justify-content: space-between;
                        align-items: center;

                        .content {
                            width: 192px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #656566;

                            span {
                                display: inline-block;
                                width: 4px;
                                height: 4px;
                                margin-bottom: 2.5px;
                                background: #1467ff;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
                        }

                        .time {
                            color: #cacbcc;
                            font-size: 12px;
                        }

                        .todeal {
                            color: #1467ff;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.charts {
    background-color: #fff;

    .el-form {
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px;
    }

    #eBox {
        width: 100%;
        min-height: 392px;
    }
}

.realtimetop {
    display: flex;
    align-items: center;
    padding: 16px 32px;

    .title {
        font-weight: bold;
        font-size: 14px;

        &:before {
            content: '';
            height: 14px;
            border-right: 3px solid #1467ff;
            margin-right: 8px;
        }

        span,
        i {
            color: #409eff;
        }

        span {
            margin: 0 10px;
            font-size: 15px;
        }

        i {
            transform: rotate(90deg);
            font-size: 18px;
            cursor: pointer;
        }
    }

    .timeTxt {
        margin: 0 8px 0 16px;
        color: #969699;
    }
}</style>
  